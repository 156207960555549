const device = {
    xlarge: 1440,
    large: 1024,
    medium: 768,
    small: 375,
}

export const isTouchDevice = () => {
    return 'ontouchstart' in window;
} 

export const isXsOnly = (width) => {
    return width < device.small;
} 

export const isSmUp = (width) => {
    return width >= device.small;
} 

export const isSmOnly = (width) => {
    return width >= device.small && width < device.medium;
} 

export const isMdUp = (width) => {
    return width >= device.medium;
}

export const isMdOnly = (width) => {
    return width >= device.medium && width < device.large;
} 

export const isLgUp = (width) => {
    return width >= device.large;
}

export const isLgOnly = (width) => {
    return width >= device.large && width <= device.xlarge;
} 

export const isXlUp = (width) => {
    return width > device.xlarge;
}