module.exports = {
    title: 'Unlock2Ride',
    home: 'Trgovina',
    montage: 'Montaža',
    about: 'Sobre',
    contact: 'Kontakt',
    blog: 'Blog',
    all: 'Todos',
    posts: 'Posts',
    'posts.seeMore': 'Ver todos os posts →',
    tags: 'Post Tags',
    'tags.allTagsLink': 'Ver todas as tags',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
        one {post}
        other {posts}
      } com a tag`,
    call: 'Agende uma call',
    'logo_alt': 'Pojdi na unlock2ride.com',
    'company_info.registrationNumber': 'Matična:',
    'company_info.taxNumber': 'Davčna:',
    'index.featured': 'Posts em destaque',
    'index.stories': 'Todos os posts',
    'notification_info.only_android_devices': 'Trenutno je aplikacija na voljo samo za Android naprave !',
    'header_info.option_1': 'Kolesarska ključavnica je pritrjena na vaše kolo',
    'header_info.option_2': 'Preprosto upravljanje z vašim pametnim telefonom',
    'header_info.option_3': 'Ne potrebujete iskati ključa ali si zapomniti kode',
    'header_info.option_4': 'Ob premiku kolesa se sproži alarm in pošlje obvestilo s točno lokacijo',
    'header_info.option_5': 'Ste izgubili kolo ? Uporabite lahko navigacijo do vašega kolesa od vaše trenutne lokacije',
    'info_secure_your_bicycle.title': 'Zavarujte svoje kolo s pametno ključavnico Unlock2Ride',
    'info_load_alarm.title': 'Glasen alarm in obveščanje ob poskusu tatvine',
    'info_load_alarm.description': 'Ključavnico zaklenete ali odklenete z vašim pametnim telefonom - potrebujete le povezavo do mobilnega ali WIFI omrežja. <br /><br />V primeru poskusa kraje, se v ključavnici sproži glasen alarm, ki opozori mimoidoče na poskus tatvine.<br /><br />Alarm se sproži že ob najmanjšem premiku, zato je prvi alarm samo opozorilo pri naslednjem alarmu boste pa obveščeni tudi vi, saj vas bo vaša ključavnica nemudoma obvestila s točno GPS lokacijo vaše ključavnice.<br /><br />Povezavo z navigacijo do točne lokacije boste prejeli v sporočilu. Vodila vas bo do vaše ključavnice in vašega kolesa. <br /><br />Sporočila lahko prejmete v 3 oblikah - odvisno od aktivne naročnine:<br /><br />',
    'info_load_alarm.option_1': 'Sporočilo SMS na vašo telefonsko številko',
    'info_load_alarm.option_2': 'E-poštno sporočilo',
    'info_load_alarm.option_3': 'Potisno sporočilo v mobilno aplikacijo Unlock2Ride',
    'info_battery_level_notification.title': 'Obveščanje o nivoju baterije kolesarske ključavnice',
    'info_battery_level_notification.description': 'Uporabniki lahko nastavijo raven baterije, pod katero se bodo sprožila obvestila.<br /><br />Ko baterija pametne ključavnice pade pod to stopnjo, bo uporabnik lahko obveščen preko obvestilnih kanalov, odvisno od aktivne naročnine:<br /><br />',
    'info_battery_level_notification.option_1': 'Sporočilo SMS na vašo telefonsko številko',
    'info_battery_level_notification.option_2': 'E-poštno sporočilo',
    'info_battery_level_notification.option_3': 'Potisno sporočilo v mobilno aplikacijo Unlock2Ride',
    'info_mandatory_equipment.title': 'Obvezna oprema vsakega kolesarja',
    'info_mandatory_equipment.robbery.title': 'Varnost',
    'info_mandatory_equipment.gpsTracking.title': 'GPS sledenje',
    'info_mandatory_equipment.bell.title': 'Glasen alarm',
    'info_mandatory_equipment.email.title': 'Obveščanje',
    'info_mandatory_equipment.forgot.title': 'Najdi kolo',
    'info_mandatory_equipment.lowBattery.title': 'Stanje baterije',
    'info_mandatory_equipment.robbery': 'Zagotovite si največjo varnost vašega kolesa z aplikacijo Unlock2Ride, ki vam ponuja:',
    'info_mandatory_equipment.gpsTracking': 'GPS sledenje vam nudi da si kadarkoli lahko ogledate trenutni položaj vašega kolesa',
    'info_mandatory_equipment.bell': 'Glasen alarm - ob premiku kolesa v primeru poskusa tatvine ali poškodbe kolesa',
    'info_mandatory_equipment.email': 'Če se sproži Alarm - prejmete nemudoma sporočilo in obvestilo na vaš telefon in mail, odvisno od aktivne naročnine',
    'info_mandatory_equipment.forgot': 'Ste pozabili kolo ? Aplikacija vam omogoča navigacijo do vašega kolesa ali željene destinacije',
    'info_mandatory_equipment.lowBattery': 'Aplikacija vas obvesti o stanju vaše baterije ko to pade pod določeno nastavljeno raven',
    'facts.rotating_slider_1.title': '“V Sloveniji vsako leto ukradejo okoli 3000 koles“',
    'facts.rotating_slider_2.title': '“Manj kot 10% koles je najdenih“',
    'facts.rotating_slider_3.title': '“Kradejo vse vrste koles, ne glede na vrednost in znamko“',
    'facts.rotating_slider_4.title': '“Na leto tatovi ukradejo približno enako število novih in starih koles“',
    'facts.rotating_slider_5.title': '“Največ kraj koles beležimo v mestih, toda to ne pomeni, da jih ne kradejo na drugih krajih“',
    'facts.rotating_slider_6.title': '“Priložnost dela tatu - če roparju otežimo delo, je več verjetnosti, da bo pri dejanju opažen in pregnan“',
    'mounting_page_title': 'Montaža ključavnice',
    'mounting_page_process': 'Postopek montaže',
    'mounting_page_brackets_info.description_1': 'Ključavnico namestimo na kolo z nosilcem za ključavnico. Načinov namestitve je več, saj se tudi modeli koles med seboj razlikujejo. Za namestitev lahko uporabite naslednje:',
    'mounting_page_brackets_info_item_1': 'objemke za pritrditev ključavnice na kolo',
    'mounting_page_brackets_info_item_2': 'set za namestitev ključavnice na zavore kolesa',
    'mounting_page_brackets_info_item_3': 'univerzalni nosilec za ključavnico je združljiv z različnimi modeli koles, zaradi svoje univerzalne zasnove',
    'mounting_page_brackets_info.description_2': 'Katerokoli možnost izberete, je pomembno, da nam omogoča montažo brez mehanskega posega v okvir kolesa.',
    'mounting_page_brackets_info.description_3': 'Vas skrbi, da ključavnica ali nosilec nista primerna za vaše kolo? Na naš elektronski naslov info@unlock2ride.com nam posredujte podatke o modelu vašega kolesa in slike.',
    'mounting_page_brackets_info.description_4': '*Ključavnico lahko uporabljate tudi brez namestitve na kolo, vendar za zagotavljanje večje varnosti kolesa priporočamo, da se ključavnico Unlock2Ride namesti na okvir kolesa.',
    'mounting_page_brackets_info.description_5': 'Montažo ključavnice vam lahko uredimo pri nas, lahko pa pri naročilu ključavnice v zadnjem koraku izberete nakup nosilca in vam montažo uredijo pri vašem pooblaščenem serviserju.',
    'mounting_page_brackets_info.condition': 'Kolesarska ključavnica se lahko namesti le, če so spodnji pogoji izpolnjeni:',
    'mounting_page_montage_condition_1': 'Ključavnica je združljiva z vsemi pnevmatikami s širino manj kot 64 mm.',
    'mounting_page_montage_condition_2': 'Kolesarski okvir mora imeti razdaljo najmanj 44 mm.',
    'mounting_page_montage_condition_3': 'Če vgradnja z obstoječim prtljažnikom ni mogoča, se ključavnica lahko namesti tudi na sprednje kolo',
    'mounting_page_montage_condition_4': 'Vgradnja nosilca je združljiva z različnimi vrstami zavor (HUB zavora, kolutna zavora, V-zavora, konzolna zavora, disk zavora) ',
    'mounting_page_montage_image_alt_text_process_1': 'Postopek montaže kolesa korak 1',
    'mounting_page_montage_image_alt_text_process_2': 'Postopek montaže kolesa korak 2',
    'mounting_page_montage_image_alt_text_process_3': 'Postopek montaže kolesa korak 3',
    'mounting_page_montage_image_alt_text_supported_bicycle_models': 'Podprti modeli koles',
    'mounting_page_montage_image_alt_text_supported_bicycle_brakes': 'PodprtiPodprte zavore za kolesa',
    'cooperation_page.title': 'Sodelovanje',
    'cooperation_page.description': 'K sodelovanju vabimo trgovine in servise s kolesarsko opremo za promocijo in montažo pametne ključavnice po Sloveniji.<br /><br />K sodelovanju vabimo tudi partnerja za izposojo koles. Sistem Unlock2Ride je pred pripravljen za sistem izmenjave koles.<br /><br /><strong>V kolikor se vam je utrnila kakšna poslovna ideja in bi potrebovali naše storitve, nas kontaktirajte na info@unlock2ride.com in z veseljem se bomo odzvali.</strong>',
    "catalog_page_price_from": "Že od {price} /mesec",
    "catalog_page_subscription_included": "Vključena naročnina za {months} mesecev",
    'catalog_page_info.text_1': 'Odpiranje brez ključa s pametnim telefonom',
    'catalog_page_info.text_2': 'Alarm ob gibanju + obvestilo na email z lokacijo in opcijsko SMS',
    'catalog_page_info.text_3': 'GPS sledenje',
    'catalog_page_info.text_4': 'Najdi moje kolo',
    'catalog_page_info.text_5': 'Alarm ob prazni bateriji + obvestilo na email in opcijsko SMS',
    'catalog_page_info.text_6': 'Navigiraj me do kolesa',
    'catalog_page_info.text_7': 'Opcijsko: dodatna obvestila SMS (Izberite ob zadnjem koraku naročila)',
    "product_page_tabs_power": "Moč",
    "product_page_tabs_gnss": "GNSS",
    "product_page_tabs_cellular": "Mobilno",
    "product_page_tabs_interface": "Vmesnik",
    "product_page_tabs_physical": "Fizično",
    "product_page_tabs_feature": "Funkcija",
    "copyright": "Vse pravice pridržane.",
    "image_alt_homepage": "Pametna kolesarska ključavnica",
    "image_alt_sim_card": "Sim kartica",
    "logout": "Odjava",
    "sign_out_logout_successfully": "Upešno ste se odjavili.",
    "checkout_discount_code": "Koda za popust",
    "checkout_discount_code_activated": "Aktivirana koda",
    "checkout_discount_code_action": "Aktiviraj",
    "checkout_discount_code_action_remove": "Odstrani",
    "checkout_discount_code_apply": "Aktiviraj kodo za popust",
    "checkout_discount_code_success": "Koda za popust uspešno aktivirana",
    "checkout_discount_code_error": "Koda za popust ne obstaja",
    "checkout_discount_code_removed": "Koda za popust je odstranjena",
    "checkout_add_to_cart": "V košarico",
    "checkout_add_to_cart_success": "Artikel dodan v košarico",
    "checkout_add_to_cart_error": "Produkt trenutno ni na voljo",
    "checkout_remove_from_cart_success": "Artikel odstranjen iz košarice",
    "checkout_remove_from_cart_error": "Napaka pri odstranjevanju artikla",
    "checkout_out_of_stock": "Ni na zalogi",
    "checkout_cart_product": "Produkt",
    "checkout_cart_description": "Opis",
    "checkout_cart_quantity": "Količina",
    "checkout_cart_price": "Cena",
    "checkout_cart_row_total_price": "Znesek",
    "checkout_cart_remove": "Odstrani",
    "checkout_cart_stripe_payment": "Plačilo",
    "checkout_cart_stripe_name": "Ime",
    "checkout_cart_stripe_card": "Kartica",
    "checkout_cart_stripe_pay": "Plačaj",
    "checkout_cart_stripe_pay_processing": "V obravnavi...",
    "checkout_cart_sidebar_products": "Produkti",
    "checkout_cart_sidebar_subscriptions": "Naročnine",
    "checkout_cart_sidebar_total_product_price": "Cena produktov",
    "checkout_cart_sidebar_total_service_price": "Cena storitev",
    "checkout_cart_sidebar_summary": "Za plačilo",
    "checkout_cart_sidebar_shipping": "Poštnina",
    "checkout_cart_sidebar_total_without_discount": "Skupaj brez popusta",
    "checkout_cart_sidebar_total_discount": "Popust",
    "checkout_cart_sidebar_total_with_discount": "Skupaj",
    "checkout_cart_sidebar_deposit": "Kavcija",
    "checkout_cart_subscription": "Naročnina",
    "checkout_cart_subscription_package": "Paket",
    "checkout_cart_subscription_package_choose": "Izberi paket",
    "checkout_cart_subscription_for": "Naročnina za",
    "checkout_cart_subscription_total": "Cena naročnin",
    "checkout_error_no_subscription_selected": "Prosimo izberite naročnino",
    "checkout_proceed": "Nadaljuj",
    "checkout_step_authentification": "Avtentifikacija",
    "checkout_step_shipping_billing": "Naslov za dostavo & plačilo",
    "checkout_step_shipping_billing_shipping_address": "Naslov za dostavo",
    "checkout_step_shipping_billing_billing_address": "Naslov za plačilo",
    "checkout_step_shipping_billing_address": "Naslov",
    "checkout_step_shipping_billing_postal_code": "Poštna številka",
    "checkout_step_shipping_billing_city": "Mesto",
    "checkout_step_shipping_billing_country": "Izberi državo",
    "checkout_step_shipping_billing_billing_address_equals_shipping_address": "Naslov za plačilo je enak naslovu za dostavo",
    "checkout_step_payment": "Plačilo",
    "checkout_cart_is_expired": "Vaša košarica je potekla",
    "checkout_cart_is_empty": "Vaša košarica je prazna",
    "settings_packages_info_price_explanation": "Če je izbrana letna naročnina, uporabnik prejme enako mesečno kvoto za letno znižano ceno.",
    "settings_packages_info_trial_information": "Po zaključenem naročilu bo ključavnica takoj aktivna.",
    "settings_packages_info_trial_first_charge_after": "Naročnina se začne obračunavati po izteku {afterMonths} mesecev",
    "settings_packages_select": "Izberi",
    "supported_country_SI": "Slovenija",
    "cart_empty": "Vaša košarica je prazna",
    "cart_go_to_checkout": "Na blagajno",
    "error_404": "Napaka 404",
    "page_not_found": "Stran ne obstaja!",
    'pages_meta_title_contact': 'Kontakt',
    'pages_meta_title_cooperation': 'Sodelovanje',
    "pages_meta_title_login": "Prijava",
    "pages_meta_title_forgot_password": "Pozabljeno geslo",
    "pages_meta_title_terms_and_conditions": "Splošni pogoji",
    "pages_meta_title_register": "Registracija",
    "cookie_title": "Spletno mesto uporablja piškotke za izboljšanje uporabniške izkušnje.",
    "cookie_accept": "Sprejmi",
    "cookie_decline": "Zavrni",
    "contact_form_submit": "Pošlji",
    "contact_form_submit_loading": "Pošiljanje...",
    "contact_form_name": "Vaše ime",
    "contact_form_email": "Vaš email",
    "contact_form_subject": "Zadeva",
    "contact_form_message": "Sporočilo",
    "contact_form_success": "Sporočilo je bilo uspešno poslano.",
    "contact_form_error": "Napaka pri pošiljanju sporočila. Prosimo pokličite nas.",
    "page_login_login": "Prijava",
    "page_login_login_start": "Prijavi se",
    "page_login_not_a_member": "Niste še registrirani?",
    "page_register_register": "Registracija",
    "page_register_register_start": "Registriraj se",
    "page_register_already_a_member": "Ste že registrirani?",
    "page_forgot_password": "Pozabljeno geslo",
    "page_forgot_password_start": "Ponastavi geslo",
    "page_forgot_password_did_you_forgot_password": "Ste pozabili geslo?",
    "general_enter_confirmation_code_title": "Verifikacija računa.",
    "general_enter_confirmation_code_description": "Za verifikacijo računa je potrebno vnesti potrditveno kodo, ki ste jo prejeli po e-pošti (preverite tudi nezaželjeno pošto).",
    "general_confirm_password": "Potrdi novo geslo",
    "forgot_password_enter_confirmation_code_description": "Za spremembo gesla je potrebno vnesti potrditveno kodo, ki ste jo prejeli po e-pošti (preverite tudi nezaželjeno pošto).",
    "formik_validation_shippingFirstname_is_too_short": "Vneseno ime je prekratko.",
    "formik_validation_shippingFirstname_is_too_long": "Vneseno ime je predolgo.",
    "formik_validation_shippingFirstname_is_required": "Prosimo vnesite vaše ime",
    "formik_validation_shippingLastname_is_too_short": "Vneseni priimek je prekaratek.",
    "formik_validation_shippingLastname_is_too_long": "Vneseno priimek je predolg.",
    "formik_validation_shippingLastname_is_required": "Prosimo vnesite vaš priimek",
    "formik_validation_shippingAddress_is_too_short": "Vneseni naslov je prekratek.",
    "formik_validation_shippingAddress_is_too_long": "Vneseni naslov je predolg.",
    "formik_validation_shippingAddress_is_required": "Prosimo vnesite naslov",
    "formik_validation_shippingPostalCode_is_too_short": "Vnesena poštna številka je prekratka.",
    "formik_validation_shippingPostalCode_is_too_long": "Vnesena poštna številka je predolga.",
    "formik_validation_shippingPostalCode_is_required": "Prosimo vnesite poštno številko",
    "formik_validation_shippingCity_is_too_short": "Vneseno mesto je prekratko.",
    "formik_validation_shippingCity_is_too_long": "Vneseno mesto je predolgo.",
    "formik_validation_shippingCity_is_required": "Prosimo vnesite mesto",
    "formik_validation_shippingCountryValue_is_too_short": "Izbrana država je prekratka.",
    "formik_validation_shippingCountryValue_is_too_long": "Izbrana država je predolga.",
    "formik_validation_shippingCountryValue_is_required": "Prosimo izberite državo",
    "formik_validation_billingFirstname_is_too_short": "Vneseno ime je prekratko.",
    "formik_validation_billingFirstname_is_too_long": "Vneseno ime je predolgo.",
    "formik_validation_billingFirstname_is_required": "Prosimo vnesite vaše ime",
    "formik_validation_billingLastname_is_too_short": "Vneseni priimek je prekaratek.",
    "formik_validation_billingLastname_is_too_long": "Vneseno priimek je predolg.",
    "formik_validation_billingLastname_is_required": "Prosimo vnesite vaš priimek",
    "formik_validation_billingAddress_is_too_short": "Vneseni naslov je prekratek.",
    "formik_validation_billingAddress_is_too_long": "Vneseni naslov je predolg.",
    "formik_validation_billingAddress_is_required": "Prosimo vnesite naslov",
    "formik_validation_billingPostalCode_is_too_short": "Vnesena poštna številka je prekratka.",
    "formik_validation_billingPostalCode_is_too_long": "Vnesena poštna številka je predolga.",
    "formik_validation_billingPostalCode_is_required": "Prosimo vnesite poštno številko",
    "formik_validation_billingCity_is_too_short": "Vneseno mesto je prekratko.",
    "formik_validation_billingCity_is_too_long": "Vneseno mesto je predolgo.",
    "formik_validation_billingCity_is_required": "Prosimo vnesite mesto",
    "formik_validation_billingCountryValue_is_too_short": "Izbrana država je prekratka.",
    "formik_validation_billingCountryValue_is_too_long": "Izbrana država je predolga.",
    "formik_validation_billingCountryValue_is_required": "Prosimo izberite državo",
    /*
    "formik_validation_address_is_too_short": "Vneseni naslov je prekratek.",
    "formik_validation_address_is_too_long": "Vneseni naslov je predolg.",
    "formik_validation_address_is_required": "Prosimo vnesite naslov",
    "formik_validation_postalCode_is_too_short": "Vnesena poštna številka je prekratka.",
    "formik_validation_postalCode_is_too_long": "Vnesena poštna številka je predolga.",
    "formik_validation_postalCode_is_required": "Prosimo vnesite poštno številko",
    "formik_validation_city_is_too_short": "Vneseno mesto je prekratko.",
    "formik_validation_city_is_too_long": "Vneseno mesto je predolgo.",
    "formik_validation_city_is_required": "Prosimo vnesite mesto",
    "formik_validation_country_is_too_short": "Vnešena država je prekratka.",
    "formik_validation_country_is_too_long": "Vnešena država je predolga.",
    "formik_validation_country_is_required": "Prosimo vnesite državo",
*/
    // IDENTICAL TO MOBILE TRANSLATIONS
    "general_firstname": "Ime",
    "general_lastname": "Priimek",
    "general_email": "Email",
    "general_telephone": "Telefon",
    "general_password": "Geslo",
    "general_dialog_box_apply": "Potrdi",
    "general_dialog_box_close": "Zapri",
    "general_dialog_box_accept": "Sprejmi",
    "general_dialog_box_decline": "Zavrni",
    "general_enter_confirmation_code": "Vnesite potrditveno kodo, ki ste jo prejeli na email.",
    "general_enter_confirmation_code_text": "Potrditvena koda",
    "general_enter_confirmation_code_not_applied": "Potrditvena koda ni bila vnešena",
    "general_error_confirmation_code_incorrect": "Nepravilna potrditvena koda. Poskusite ponovno.",
    "general_error_internal_application_error": "Napaka v aplikaciji",
    "general_error_server_not_available": "Strežnik ni dostopen",
    "general_error_content_not_available": "Vsebina ni na voljo",
    "general_error_email_or_password_wrong": "Email ali geslo je napačno",
    "settings_change_password_old_password": "Staro geslo",
    "settings_change_password_new_password": "Novo geslo",
    "settings_change_password_confirm_password": "Potrdi novo geslo",
    "settings_packages_basic": "Basic",
    "settings_packages_standard": "Standard",
    "settings_packages_advanced": "Advanced",
    "settings_packages_premium": "Premium",
    "settings_packages_price_per_month": "mesec",
    "settings_packages_price_per_year": "leto",
    "settings_packages_info_alarm_battery_notification": "Obveščanje o stanju baterije",
    "settings_packages_info_alarm_movement_notification": "Obveščanje o nedovoljenemu premiku kolesa",
    "settings_packages_info_bike_lock": "Ključavnica za kolo",
    "settings_packages_info_bike_lock_gps_positioning": "Aktivno Gps sledenje",
    "settings_packages_info_bike_lock_sim_card_subscription": "Aktivna sim kartica",
    "settings_packages_info_maps": "Mapa",
    "settings_packages_info_maps_autocomplete": "Iskanje po mapi",
    "settings_packages_info_maps_directions": "Navodila za pot",
    "settings_packages_info_maps_navigate": "Navigacija",
    "settings_packages_info_notification_type_email": "Email",
    "settings_packages_info_notification_type_sms": "Sms",
    "settings_packages_info_notification_type_push_notification": "Potisna sporočila",
    "settings_packages_info_unlimited_usage": "Neomejeno",
    "settings_packages_info_included": "Vključeno",
    "settings_packages_info_limited_usage": "mesec",
    "settings_packages_info_price_for_each_additional_bike_lock": "Uporabniki z aktivno naročnino lahko dodajajo dodatno ključavnico za %{PricePerMonth} na mesec ali za %{pricePerYear} na leto za ta paket.",
    "forgot_password": "Pozabljeno geslo",
    "forgot_password_submit": "Ponastavi geslo",
    "forgot_password_error_user_action_denied": "Uporabnik ne obstaja ali pa ni verificiran.",
    "forgot_password_success": "Geslo uspešno spremenjeno.",
    "sign_in_login_successfully": "Uspešno ste se prijavili.",
    "sign_in_wrong_username_or_password": "Napačno uporabniško ime ali geslo.",
    "formik_validation_name_is_too_short": "Vneseno ime je prekratko.",
    "formik_validation_name_is_too_long": "Vneseno ime je predolgo.",
    "formik_validation_name_is_required": "Prosimo vnesite vaše ime.",
    "formik_validation_subject_is_too_short": "Vneseni naslov je prekratek.",
    "formik_validation_subject_is_too_long": "Vneseni naslov je predolg.",
    "formik_validation_subject_is_required": "Prosimo vnesite naslov.",
    "formik_validation_message_is_too_short": "Vneseno sporočilo je prekratko.",
    "formik_validation_message_is_too_long": "Vneseno sporočilo je predolgo.",
    "formik_validation_message_is_required": "Prosimo vnesite sporočilo.",
    "formik_validation_firstname_is_too_short": "Vneseno ime je prekratko.",
    "formik_validation_firstname_is_too_long": "Vneseno ime je predolgo.",
    "formik_validation_firstname_is_required": "Prosimo vnesite vaše ime",
    "formik_validation_lastname_is_too_short": "Vneseni priimek je prekaratek.",
    "formik_validation_lastname_is_too_long": "Vneseno priimek je predolg.",
    "formik_validation_lastname_is_required": "Prosimo vnesite vaš priimek",
    "formik_validation_password_is_too_short": "Geslo mora biti daljše od 3 znakov.",
    "formik_validation_password_is_too_long": "Geslo ne sme biti daljše od 20 znakov.",
    "formik_validation_password_is_required": "Prosimo vnesite geslo",
    "formik_validation_oldPassword_is_too_short": "Geslo mora biti daljše od 3 znakov.",
    "formik_validation_oldPassword_is_too_long": "Geslo ne sme biti daljše od 20 znakov.",
    "formik_validation_oldPassword_is_required": "Prosimo vnesite geslo",
    "formik_validation_newPassword_is_too_short": "Geslo mora biti daljše od 3 znakov.",
    "formik_validation_newPassword_is_too_long": "Geslo ne sme biti daljše od 20 znakov.",
    "formik_validation_newPassword_is_required": "Prosimo vnesite geslo",
    "formik_validation_confirmPassword_is_too_short": "Geslo mora biti daljše od 3 znakov.",
    "formik_validation_confirmPassword_is_too_long": "Geslo ne sme biti daljše od 20 znakov.",
    "formik_validation_confirmPassword_is_required": "Prosimo vnesite potrditveno geslo",
    "formik_validation_confirmPassword_is_not_one_of": "Gesli se ne ujemata",
    "formik_validation_email_is_required": "Prosimo vnesite vaš email naslov",
    "formik_validation_email_is_invalid": "Prosimo vnesite veljaven email naslov",
    "formik_validation_termsAccepted_is_not_one_of": "Strinjanje s splošnimi pogoji je obvezno",
    "formik_validation_privacyPolicyAccepted_is_not_one_of": "Strinjanje s politiko zasebnosti je obvezno",
    "formik_validation_phone_number": "Neveljavna telefonska številka",
    "sign_up_confirm_password": "Potrditveno geslo",
    "sign_up_agree_with_terms_and_conditions": "Strinjam se s splošnimi pogoji",
    "sign_up_already_have_account_sign_in": "Že imate račun? Prijavite se",
    "sign_up_registration_completed": "Uspešno ste se registrirali",
    "sign_up_registration_error": "Napaka pri registraciji uporabnika",
    "policy_privacy_title": "Varovanje osebnih podatkov",
    "policy_privacy_agree": "Strinjam se, da lahko podjetje KreLab, programiranje in svetovanje, Tilen Kreča s.p., kot upravljalec spletne strani zgoraj opisane podatke obdeluje z namenom   ugotavljanja mojega zanimanja za izdelke, storitve in druge rešitve omenjenega podjetja ter me v zvezi z njimi kontaktira preko e-pošte ali telefona. Potrjujem, da sem seznanjen z varovanjem osebnih podatkov.",
    "policy_privacy_additional_info_title": "Zagotovilo zasebnosti",
    "policy_privacy_additional_info_description": "Podatki iz obrazca Kontaktni obrazec ne bodo posredovani pod nobenim pogojem tretji osebi ali uporabljeni za katerikoli drugi namen z izjemo namena vsebine oddanega sporočila na kontaktnem obrazcu ali sklenitve poslovnega razmerja.",
};