import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import { MDBBtn, MDBModal } from 'mdbreact';
import styled from 'styled-components';
export var StyledMDBBtn = styled(MDBBtn)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n    white-space: nowrap;\n"]))); // https://dabblet.com/gist/d6ebc6c14bd68a4b06a6
export var LinkableSvg = styled.a(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  position: relative; \n  z-index: 2;\n"])));
export var LogoWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  justify-content: center;\n  padding-bottom: 1rem;\n"])));
export var PopupModal = styled(MDBModal)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n    top: 25%;\n    padding-left: 3rem;\n    padding-right: 3rem;\n    @media (max-width: 768px) { \n        padding-left: unset;\n        padding-right: unset;\n    }\n"])));
export var PopupHeader = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  display: flex;\n  background-color: #cccc;\n  padding-top: 1.1rem;\n  padding-bottom: 1rem;\n  text-align: center;\n"])));
export var PopupHeaderTitle = styled.h4(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  flex: 3;\n  padding: 0rem;\n  text-align: left;\n  padding-left: 1rem;\n"])));
export var PopupHeaderA = styled.a(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose(["\n  flex: 1;\n  text-align: right;\n  padding-right: 1rem;\n"])));
