import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
// @ts-nocheck
import styled, { css } from 'styled-components';
var buttonStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n\tbackground-color: black;\n\tcolor: white;\n\tborder: none;\n\n\t&:hover {\n\t\tbackground-color: white;\n\t\tcolor: black;\n\t\tborder: 1px solid black;\n\t}\n"])));
var googleSignInStyles = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n\tbackground-color: #4258f4;\n\tcolor: white;\n\tborder: 1px solid #357ae8;\n\n\t&:hover {\n    \tbackground-color: #4258f4;\n    \tcolor: wheat;\n\t\tborder: 1px solid black;\n\t}\n"])));
var invertedStyles = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n\tbackground-color: white;\n\tcolor: black;\n\tborder: 1px solid black;\n\n\t&:hover {\n\t\tbackground-color: black;\n\t\tcolor: white;\n\t\tborder: none;\n\t}\n"])));
var getButtonStyles = function getButtonStyles(props) {
    if (props.isGoogleSignIn) {
        return googleSignInStyles;
    }
    return props.inverted ? invertedStyles : buttonStyles;
};
export var FormButtonContainer = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n\tmin-width: 165px;\n\twidth: auto;\n\theight: 50px;\n\tletter-spacing: 0.5px;\n\tline-height: 50px;\n\tpadding: 0 35px 0 35px;\n\tfont-size: 15px;\n\ttext-transform: uppercase;\n\t//font-family: 'Open Sans Condensed';\n\tfont-weight: bolder;\n\tcursor: pointer;\n\tdisplay: flex;\n  \tjustify-content: center;\n  \n\t", "\n"])), getButtonStyles);
