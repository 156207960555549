export const verificationCodeLength = 4;

export const generateVerificationCode = () => {
  return parseInt(Math.floor(100000 + Math.random() * 900000).toString().substring(0, verificationCodeLength), 10);
};

export const generateUniqueId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

export const prepareSlug = (langKey, route) => {
  return `${langKey !== 'sl' ? `/${langKey}` : '/'}${route}`.replace('//', '/');
};

