import { useEffect, useState } from 'react';
import { isLgOnly, isMdOnly, isSmOnly, isXsOnly, isXlUp, isLgUp, isMdUp, isSmUp } from '../services/mediaQuery'

// https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    const [deviceIsXsOnly, setDeviceIsXsOnly] = useState(null)
    const [deviceIsSmUp, setDeviceIsSmUp] = useState(null)
    const [deviceIsSmOnly, setDeviceIsSmOnly] = useState(null)
    const [deviceIsMdUp, setDeviceIsMdUp] = useState(null)
    const [deviceIsMdOnly, setDeviceIsMdOnly] = useState(null)
    const [deviceIsLgUp, setDeviceIsLgUp] = useState(null)
    const [deviceIsLgOnly, setDeviceIsLgOnly] = useState(null)
    const [deviceIsXlUp, setDeviceIsXlUp] = useState(null)

    useEffect(() => {
      
      // Handler to call on window resize
      /*
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setDeviceIsXsOnly(isXsOnly(window.innerWidth))
        setDeviceIsSmUp(isSmUp(window.innerWidth))
        setDeviceIsSmOnly(isSmOnly(window.innerWidth))
        setDeviceIsMdUp(isMdUp(window.innerWidth))
        setDeviceIsMdOnly(isMdOnly(window.innerWidth))
        setDeviceIsLgUp(isLgUp(window.innerWidth))
        setDeviceIsLgOnly(isLgOnly(window.innerWidth))
        setDeviceIsXlUp(isXlUp(window.innerWidth))
      } */

      const debouncedHandleResize = debounce(function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setDeviceIsXsOnly(isXsOnly(window.innerWidth))
        setDeviceIsSmUp(isSmUp(window.innerWidth))
        setDeviceIsSmOnly(isSmOnly(window.innerWidth))
        setDeviceIsMdUp(isMdUp(window.innerWidth))
        setDeviceIsMdOnly(isMdOnly(window.innerWidth))
        setDeviceIsLgUp(isLgUp(window.innerWidth))
        setDeviceIsLgOnly(isLgOnly(window.innerWidth))
        setDeviceIsXlUp(isXlUp(window.innerWidth))
      }, 50)

  
      // Add event listener
      window.addEventListener("resize", debouncedHandleResize);
  
      // Call handler right away so state gets updated with initial window size
      debouncedHandleResize();
  
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", debouncedHandleResize);
    }, []); // Empty array ensures that effect is only run on mount
  
    return {
      windowSize,
      deviceIsXsOnly,
      deviceIsSmUp,
      deviceIsSmOnly,
      deviceIsMdUp,
      deviceIsMdOnly,
      deviceIsLgUp,
      deviceIsLgOnly,
      deviceIsXlUp
    };
  }