function removeProductionCombinationIds(currentIds, removeIds) {
    const newIds = []
    currentIds.forEach((currentId) => {
        let countMatch = 0
        removeIds.forEach((removeId) => {
            Object.keys(currentId).forEach((index) => {
                if (removeId.productId === currentId[index]) {
                    countMatch++;
                }
            })
        })
        if (countMatch !== removeIds.length) {
            newIds.push(currentId)
        }
    })
    return newIds
}

function addProductionCombinationIds(currentIds, addIds) {
    currentIds.push(addIds) // if multiple items will be added also qunatity correction needs to be reworked, currently always +1
    return currentIds
}

export const groupSameItemsInCart = (cartItems, cartItemToAdd) => {
    // reserveIds comes as an object and represents everytime only last added product in cart
    // whole list of products is stored in ids property
    const existingCartItem = cartItems.find(item => item.id === cartItemToAdd.id)

    if (existingCartItem) {
        return cartItems.map(item =>
            item.id === cartItemToAdd.id
                ? { ...item, quantity: item.quantity + 1, ids: addProductionCombinationIds(item.ids, cartItemToAdd.reserveIds) }
                : item
        )
    }

    return [...cartItems, { ...cartItemToAdd, quantity: 1, ids: [cartItemToAdd.reserveIds] }]
}

export const decreaseQtyAndDeleteItem = (cartItems, cartItemToRemove) => {
    const existingCartItem = cartItems.find(item => item.id === cartItemToRemove.id)

    if (existingCartItem.quantity === 1) {
        return cartItems.filter(item => item.id !== cartItemToRemove.id)
    }
    return cartItems.map(item =>
        item.id === cartItemToRemove.id
            ? {
                ...item,
                quantity: item.quantity - 1,
                ids: removeProductionCombinationIds(item.ids, cartItemToRemove.releaseIds)
            }
            : item
    )
}