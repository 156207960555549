const cartActionTypes = {
    SET_DISCOUNT_CODE: 'SET_DISCOUNT_CODE',
    TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
    SET_CART_HIDDEN: 'SET_CART_HIDDEN',
    SET_CART_ID: 'SET_CART_ID',
    ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
    UPDATE_CART: 'UPDATE_CART',
    UPDATE_CART_ITEM: 'UPDATE_CART_ITEM',
    DECREASE_QTY_AND_DELETE_ITEM: 'DECREASE_QTY_AND_DELETE_ITEM',
    DELETE_ITEM_FROM_CART: 'DELETE_ITEM_FROM_CART',
    CLEAR_CART: 'CLEAR_CART',
    MANAGE_SINGLE_ITEM_IN_CART: 'MANAGE_SINGLE_ITEM_IN_CART'
}

export default cartActionTypes