import { createSelector } from 'reselect'
import path from 'ramda/src/path'
import pathOr from 'ramda/src/pathOr'
import { defaultCountryCode, extractData } from '../shop/shop-sagas'

export const selectCart = (state) => state.cart

const getTotal = (cart) => {
    return parseFloat(
            getPartialCartTotal(cart, false) + 
            getCartDeposit(cart, false) + 
            getServicesCartTotal(cart, false) + 
            getShippingTotal(cart, false)
        ).toFixed(2)
}

const getTotalWithDiscount = (cart) => {
    return parseFloat(
        getPartialCartTotal(cart, true) + 
        getCartDeposit(cart, true) + 
        getServicesCartTotal(cart, true) + 
        getShippingTotal(cart, true)
    ).toFixed(2)
}

const getTotalSubscriptionDiscount = (cart) => {
    let totalDiscountableAmount = parseFloat(getSubscriptionTotal(cart.cartItems))
    let totalDiscount = 0
    if (cart?.discountCode?.subscription?.enabled) {
        if (cart?.discountCode?.subscription?.amountIsPercentage) {
            // Percentage discount code
            totalDiscount = (totalDiscountableAmount - (100 - cart.discountCode.subscription.amount) * totalDiscountableAmount / 100).toFixed(2)
        } else {
            // Fixed discount code
            if (totalDiscountableAmount > cart?.discountCode?.subscription?.amount) {
                totalDiscount = parseFloat(cart?.discountCode?.subscription?.amount, 10).toFixed(2)
            } else {
                totalDiscount = totalDiscountableAmount
            }
        }
    }

    if (totalDiscount === totalDiscountableAmount) {
        // If total discount is the same as actual amount we charge 1 EUR, otherwise stripe returns error if we charge 0
        // Stripe error: Amount must convert to at least 50 cents. €0.01 converts to approximately $0.01.
        totalDiscount = totalDiscount - 1.00;
    }
    return totalDiscount
}

const getTotalDiscount = (cart) => {
    return parseFloat(getTotal(cart) - getTotalWithDiscount(cart), 10).toFixed(2);
}

const getSubscriptionTotalWithDiscount = (cart) => {
    const totalDiscount = getTotalSubscriptionDiscount(cart)
    const total = getSubscriptionTotal(cart.cartItems)
    return parseFloat(total - totalDiscount, 10).toFixed(2)
}

function getSubscriptionItemPrice(cartItem) {
    if (path(['subscription', 'interval'], cartItem) === 'pricePerMonth') {
        return pathOr(0, ['subscription', 'pricePerMonth'], cartItem)
    } if (path(['subscription', 'interval'], cartItem) === 'pricePerYear') {
        return pathOr(0, ['subscription', 'pricePerYear'], cartItem)
    }
    return pathOr(0, ['subscription', 'pricePerMonth'], cartItem)

}

function getSubscriptionTotal(cartItems) {
    return cartItems.reduce((ac, cv) => ac + (getSubscriptionItemPrice(cv) * cv.quantity), 0)
}

function getItemServices(cart, cv, returnWithDiscountCode) {
    let totalPricing = 0;
    Object.keys(cv.services.selected).forEach((key) => {
        if (cv.services.selected[key]) {
            const priceData = extractData(cv.services.pricing[key], 'country', cart.settings.countryCode, null, defaultCountryCode)
            if (returnWithDiscountCode) {
                totalPricing += parseFloat(priceData.priceWithDiscountCode, 10) * cv.quantity
            } else {
                totalPricing += parseFloat(priceData.price, 10) * cv.quantity
            }
        }
    })
    return totalPricing
}

function getServicesCartTotal(cart, returnWithDiscountCode) {
    return cart.cartItems.reduce((ac, cv) => ac + getItemServices(cart, cv, returnWithDiscountCode), 0)
}

function getPartialCartTotal(cart, returnWithDiscountCode) {
    if (returnWithDiscountCode) {
        return cart.cartItems.reduce((ac, cv) => ac + (!cv.priceInfo.deposit ? cv.priceInfo.priceWithDiscountCode * cv.quantity : 0), 0) +
            pathOr([], ['items'], cart).reduce((ac, cv) => ac + (cv.priceInfo.priceWithDiscountCode * cv.quantity), 0)

    } else {
        return cart.cartItems.reduce((ac, cv) => ac + (!cv.priceInfo.deposit ? cv.priceInfo.priceWithDiscount * cv.quantity : 0), 0) +
            pathOr([], ['items'], cart).reduce((ac, cv) => ac + (cv.priceInfo.priceWithDiscount * cv.quantity), 0)
    }
}

function getCartDeposit(cart, returnWithDiscountCode) {
    if (returnWithDiscountCode) {
        return cart.cartItems.reduce((ac, cv) => ac + (cv.priceInfo.deposit ? cv.priceInfo.priceWithDiscountCode * cv.quantity : 0), 0)
    } else {
        return cart.cartItems.reduce((ac, cv) => ac + (cv.priceInfo.deposit ? cv.priceInfo.priceWithDiscount * cv.quantity : 0), 0)
    }
}

function getShippingTotal(cart, returnWithDiscountCode) {
    let totalWeight = 0;
    let isFree = false;
    cart.cartItems.map((item) => {
        if (item.services.selected.montage) {
            isFree = true;
        }
        totalWeight+=item.shippingInformation.weight * item.quantity;
    })
    cart.items.map((item) => {
        totalWeight+=item.shippingInformation.weight * item.quantity;
    })

    if (isFree) {
        return 0;
    }
    if (totalWeight < 1000) {
        return 4.99;
    } else if (totalWeight < 2000) {
        return 5.99;
    } else if (totalWeight < 3000) {
        return 6.99;
    } else if (totalWeight < 4000) {
        return 7.99;
    } else {
        return 9.99;
    }
    
/*
    console.log("Total weight of order is ", totalWeight);


    if (returnWithDiscountCode) {
        return cart.cartItems.reduce((ac, cv) => ac + (cv.services.selected.montage ? 0 : cv.priceInfo.shippingWithDiscountCode * cv.quantity), 0)
    } else {
        return cart.cartItems.reduce((ac, cv) => ac + (cv.services.selected.montage ? 0 : cv.priceInfo.shipping * cv.quantity), 0)
    }
    */
}

export const selectCartId = createSelector(
    [selectCart],
    (cart) => cart.id
)

export const selectCartSettings = createSelector(
    [selectCart],
    (cart) => cart.settings
)

export const selectCartItems = createSelector(
    [selectCart],
    (cart) => pathOr([], ['cartItems'], cart)
)

export const selectDiscountCode = createSelector(
    [selectCart],
    (cart) => pathOr(null, ['discountCode', 'code'], cart)
)

export const selectCartDropDownHidden = createSelector(
    [selectCart],
    (cart) => cart.hidden
)

export const selectCartItemsCount = createSelector(
    [selectCart],
    (cart) => {
        return cart.cartItems.reduce((ac, cv) => ac + cv.quantity, 0) + pathOr([], ['items'], cart).reduce((ac, cv) => ac + cv.quantity, 0)
    }
)

export const selectCartTotalBeforeDiscount = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getPartialCartTotal(cart, false)).toFixed(2)
    }
)


export const selectShippingTotalBeforeDiscount = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getShippingTotal(cart, false)).toFixed(2)
    }
)

export const selectCartDepositBeforeDiscount = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getCartDeposit(cart, false)).toFixed(2)
    }
)

export const selectCartServicesTotalBeforeDiscount = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getServicesCartTotal(cart, false)).toFixed(2)
    }
)


export const selectCartTotal = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getPartialCartTotal(cart, true)).toFixed(2)
    }
)

export const selectCartDeposit = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getCartDeposit(cart, true)).toFixed(2)
    }
)

export const selectCartServicesTotal = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getServicesCartTotal(cart, true)).toFixed(2)
    }
)

export const selectShippingTotal = createSelector(
    [selectCart],
    (cart) => {
        return parseFloat(getShippingTotal(cart, true)).toFixed(2)
    }
)

export const selectTotal = createSelector(
    [selectCart],
    (cart) => {
        return getTotal(cart)
    }
)

export const selectTotalDiscount = createSelector(
    [selectCart],
    (cart) => {
        return getTotalDiscount(cart)
    }
)

export const selectSubscriptionTotalDiscount = createSelector(
    [selectCart],
    (cart) => {
        return getTotalSubscriptionDiscount(cart)
    }
)

export const selectTotalWithDiscount = createSelector(
    [selectCart],
    (cart) => {
        return getTotalWithDiscount(cart)
    }
)

export const selectSubscriptionTotalWithDiscount = createSelector(
    [selectCart],
    (cart) => {
        return getSubscriptionTotalWithDiscount(cart)
    }
)

export const selectSubscriptionTotal = createSelector(
    [selectCartItems],
    (cartItems) => {
        return parseFloat(getSubscriptionTotal(cartItems)).toFixed(2)
    }
)

export const selectRequireSubscription = createSelector(
    [selectCartItems],
    (cartItems) => {
        let requireSubscription = false
        cartItems.map((cartItem) => {
            if (cartItem.isSubscriptionMandatory) {
                requireSubscription = true
            }
        })
        return requireSubscription
    }
)

export const selectIsSubscriptionSelected = createSelector(
    [selectCartItems],
    (cartItems) => {
        let isSelected = true
        cartItems.map((cartItem) => {
            if (cartItem.isSubscriptionMandatory && !path(['subscription', 'pricePerMonth'], cartItem) && !path(['subscription', 'pricePerYear'], cartItem)) {
                isSelected = false
            }
        })
        return isSelected
    }
)
