import pathOr from 'ramda/src/pathOr'
import path from 'ramda/src/path'
import { store } from '../store';
import { fetchInProgress } from "./shop-actions"
import axios from "axios";

export const defaultCountryCode = 'SI';
export const defaultLanguage = 'sl';

export function extractData(data, comparingFieldName, comparingFieldValue, desiredFieldName, defaultValue) {
    let response = null
    let defaultResponse = null
    data.map((dataItem) => {
        if (dataItem[comparingFieldName] === comparingFieldValue) {
            if (desiredFieldName !== null) {
                response = dataItem[desiredFieldName]
            } else {
                response = dataItem
            }
        }
        if (dataItem[comparingFieldName] === defaultValue) {
            if (desiredFieldName !== null) {
                defaultResponse = dataItem[desiredFieldName]
            } else {
                defaultResponse = dataItem
            }
        }
    })
    if (response) {
        return response;
    } 
    return defaultResponse;
}


export async function getUserCountryCode() {
    // FREE tier 30.000 requests per month
    return await fetch("https://ipapi.co/json/", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': '*'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then((response) => {
            return response.country_code;
        }).catch((error) => {
            console.log("Error occureed while trying to get country code from https://ipapi.co/json/", error);
            return defaultCountryCode;
        });
}
export async function getItems () {
    const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/kuzzleGetItems',
        data: JSON.stringify({
            additionalConditions: [
                {
                    term: {
                        type: 'product'
                    },
                }
            ]
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    return pathOr([], ['data', 'msg', 'results', 'hits'], response)
}

export async function getProducts(countryCode, options) {
    store.dispatch(fetchInProgress(true))
    const items = []
    await fetch("/.netlify/functions/kuzzleGetProductsCombinations", {
        method: "POST",
        body: JSON.stringify({
            id: options.id,
            productSlug: options.slug || null
        }),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then((data) => {
            status = data.status;
            return data.json()
        })
        .then(async (response) => {
            if (status === '200') {
                pathOr([], ['msg', 'results', 'hits'], response).map(async (productCombination) => {
                    let imageUrl = ''
                    let icon = {}
                    let effect;

                    if (pathOr(false, ['_source', 'conditions', 'properties', 'bikeLock', 'included'], productCombination) &&
                        pathOr(false, ['_source', 'conditions', 'properties', 'solarPanel', 'included'], productCombination)) {
                        imageUrl = '/shop/concox-bl10_5.jpeg'
                        icon = {
                            name: 'solar-panel',
                            className: 'green-text pr-2'
                        }
                        effect = 'fadeInLeft'
                    } else if (pathOr(false, ['_source', 'conditions', 'properties', 'bikeLock', 'included'], productCombination) &&
                        !path(['_source', 'conditions', 'properties', 'solarPanel', 'included'], productCombination)) {
                        imageUrl = '/shop/concox-bl10_1.jpeg'
                        icon = {
                            name: 'lock-open',
                            className: 'pink-text pr-2'
                        }
                        effect = 'fadeInLeft'
                    }

                    // const price = await getPriceForCountryCode(pathOr('', ['_source', 'priceDefinitions'], productCombination), countryCode)

                    // extractData(data, 'language', 'en', 'text', 'en')
                    const priceInfo = extractData(pathOr([], ['_source', 'priceDefinitions'], productCombination), 'country', countryCode, null, defaultCountryCode)

                    console.log("productCombination", productCombination)

                    items.push(
                        {
                            // ...productCombination._source, // TODO
                            id: productCombination._id,
                            productPageExists: pathOr(false, ['_source', 'gatsby', 'productPageExists'], productCombination),
                            ids: [],
                            imageUrl,
                            allPriceInfo: pathOr([], ['_source', 'priceDefinitions'], productCombination),
                            isSubscriptionMandatory: pathOr(false, ['_source', 'isSubscriptionMandatory'], productCombination),
                            trailingPeriodInDays: pathOr(0, ['_source', 'trailingPeriodInDays'], productCombination),
                            services: {
                                selected: {},
                                pricing: {}
                            },
                            slugInformation: pathOr([], ['_source', 'slugInformation'], productCombination),
                            shippingInformation: pathOr([], ['_source', 'shippingInformation'], productCombination),
                            priceInfo: {
                                deposit: priceInfo.deposit,
                                country: priceInfo.country,
                                priceWithoutDiscount: priceInfo.priceWithoutDiscount,
                                priceWithDiscount: priceInfo.priceWithDiscount,
                                priceWithDiscountCode: priceInfo.priceWithDiscount,
                                priceWithDiscountCodeInfoAmount: 0,
                                priceWithDiscountCodeInfoIsPercentage: false,
                                currency: priceInfo.currency,
                                currencySign: priceInfo.currencySign,
                                shipping: priceInfo.shipping,
                                shippingWithDiscountCode: priceInfo.shipping,
                                shippingWithDiscountCodeInfoAmount: 0,
                                shippingWithDiscountCodeInfoIsPercentage: false,
                                priceFrom: pathOr(null, ['msg', 'results', 'priceFrom'], response)
                            },
                            quantity: {
                                available: pathOr('0', ['_source', 'quantity', 'available'], productCombination),
                            },
                            payload: {
                                text: {
                                    title: {
                                        sl: extractData(pathOr('', ['_source', 'name'], productCombination), 'language', 'sl', 'text', defaultLanguage),
                                        en: extractData(pathOr('', ['_source', 'name'], productCombination), 'language', 'en', 'text', defaultLanguage),
                                    },
                                    description: {
                                        sl: extractData(pathOr('', ['_source', 'description'], productCombination), 'language', 'sl', 'text', defaultLanguage),
                                        en: extractData(pathOr('', ['_source', 'description'], productCombination), 'language', 'en', 'text', defaultLanguage),
                                    }
                                },
                                icon,
                                design: {
                                    effect
                                }
                            }
                        }
                    )
                })
            }
        })
        .catch((err) => {
            console.error('Error occured while trying to get data from kuzzleGetProductsCombinations', err)
        })
    store.dispatch(fetchInProgress(false))

    return items
}