const languages = require('./languages');

const config = {
  companyName: "KreLab, programiranje in svetovanje, Tilen Kreča s.p.",
  siteTitle: "Unlock2Ride", // Site title.
  siteTitleShort: "Unlock2Ride", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteUrl: "https://www.unlock2ride.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Secure you bycicle from unatempted theft with Unlock2Ride smart lock", // Website description used for RSS feeds/meta description tag.
  googleAnalyticsID: "UA-204381954-1", // Unlock2Ride
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 4, // Amount of posts displayed per listing page.
  author: {
    name: 'Tilen Kreča'
  },
  languages,
  menu: [
    { label: 'home', slug: '/' },
    { label: 'montage', slug: '/montage/' },
    { label: 'contact', slug: '/contact/' },
  ],
  themeColor: "#7CAA2D", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = config;
