import cartActionTypes from './cart-types'

export const setDiscountCode = (discountData) => ({
    type: cartActionTypes.SET_DISCOUNT_CODE,
    payload: discountData
})

export const toggleCartHidden = () => ({
    type: cartActionTypes.TOGGLE_CART_HIDDEN
})

export const setCartHidden = (hidden) => ({
    type: cartActionTypes.SET_CART_HIDDEN,
    payload: hidden
})

export const addCartId = (cartId) => ({
    type: cartActionTypes.SET_CART_ID,
    payload: cartId
})

export const addItemToCart = (item) => ({
    type: cartActionTypes.ADD_ITEM_TO_CART,
    payload: item
})

export const manageSingleItemInCart = (item) => ({
    type: cartActionTypes.MANAGE_SINGLE_ITEM_IN_CART,
    payload: item
})

export const updateCart = (cart) => ({
    type: cartActionTypes.UPDATE_CART,
    payload: cart
})

export const updateCartItem = (cartItem) => ({
    type: cartActionTypes.UPDATE_CART_ITEM,
    payload: cartItem
})

export const decreaseQuantity = (item) => ({
    type: cartActionTypes.DECREASE_QTY_AND_DELETE_ITEM,
    payload: item
})

export const deleteItemFromCart = (item) => ({
    type: cartActionTypes.DELETE_ITEM_FROM_CART,
    payload: item
})

export const clearCart = () => ({
    type: cartActionTypes.CLEAR_CART
})