// @ts-nocheck
import styled from 'styled-components';
import { flexbox, layout, space, color } from 'styled-system';
var FixedContainer = styled('section')({
    maxWidth: function maxWidth(_ref) {
        var t = _ref.theme;
        return t.maxWidth;
    },
    padding: function padding(_ref2) {
        var t = _ref2.theme;
        return "0 " + t.scaleN(2) + "rem";
    }
}, color, flexbox, layout, space);
FixedContainer.displayName = 'FixedContainer';
FixedContainer.propTypes = Object.assign({}, color.propTypes, space.propTypes, flexbox.propTypes, layout.propTypes);
export default FixedContainer;
