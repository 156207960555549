// IMPORTANT https://redux.js.org/recipes/troubleshooting#never-mutate-reducer-arguments
import cartActionTypes from './cart-types'
import { groupSameItemsInCart, decreaseQtyAndDeleteItem } from './cart-utils'
import mergeDeepRight from 'ramda/src/mergeDeepRight'

const INITIAL_STATE = {
    id: null,
    hidden: true,
    cartItems: [],
    items: [],
    settings: {
        selected: {},
        pricing: {}
    }
}

// https://medium.com/@jeffbski/where-do-i-put-my-business-logic-in-a-react-redux-application-9253ef91ce1
const cartReducer = (state = INITIAL_STATE, action) => {
    // If this block is added we have issues setting id property on cart due to ovverriding value by initial state on cart add item action
    // if (!path(['cartItems', '0'], state)) {
    //    state = INITIAL_STATE
    // }
    switch (action.type) {
        case cartActionTypes.SET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case cartActionTypes.SET_DISCOUNT_CODE:
            return {
                ...state,
                discountCode: action.payload
            }
        case cartActionTypes.TOGGLE_CART_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            }
        case cartActionTypes.SET_CART_HIDDEN:
            return {
                ...state,
                hidden: action.payload
            }
        case cartActionTypes.SET_CART_ID:
            return {
                ...state,
                id: action.payload
            }
        case cartActionTypes.ADD_ITEM_TO_CART:
            return {
                ...state,
                cartItems: groupSameItemsInCart(state.cartItems, action.payload)
            }
        case cartActionTypes.MANAGE_SINGLE_ITEM_IN_CART:
            return {
                ...state,
                items: action.payload
            }
        case cartActionTypes.UPDATE_CART:
            return {
                ...state,
                cartItems: action.payload.cartItems,
                settings: action.payload.settings,
            }
        case cartActionTypes.UPDATE_CART_ITEM:
            /*
            !!WRONG
            state.cartItems.forEach(function(element,index){
                if (state.cartItems[index].id === action.payload.id) {
                    state.cartItems[index] = action.payload
                }
            });
            return {
                ...state
            };
            */
            return {
                ...state,
                cartItems: state.cartItems.map((cartItem, index) => {
                    if (state.cartItems[index].id === action.payload.id) {
                        return mergeDeepRight(cartItem, action.payload)
                    }
                    return cartItem
                })
                /*
                cartItems: state.cartItems.map((cartItem, index) => {
                    if (state.cartItems[index].id === action.payload.id) {
                        console.log("INCOMING PAYLOAD TEST", mergeDeepLeft(cartItem.services, action.payload.services))
                        console.log("INCOMING PAYLOAD TEST 2", mergeDeepRight(cartItem.services, action.payload.services))
                        console.log("INCOMING PAYLOAD TEST 3", mergeAll(cartItem.services, action.payload.services))
                        return { 
                            id: cartItem.id,
                            priceInfo: {
                                ...cartItem.priceInfo,
                                ...action.payload.priceInfo
                            },
                            subscription: action.payload.subscription,
                            services: action.payload.services,
                            test: mergeDeepLeft(cartItem.services, action.payload.services)
                            // services: mergeDeepLeft(cartItem.services, action.payload.services)
                        }
                    }
                    return cartItem
                })
                */
            }

        case cartActionTypes.DECREASE_QTY_AND_DELETE_ITEM:
            return {
                ...state,
                cartItems: decreaseQtyAndDeleteItem(state.cartItems, action.payload)
            }
        case cartActionTypes.DELETE_ITEM_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.payload.id)
            }
        case cartActionTypes.CLEAR_CART:
            return {
                ...state,
                cartItems: [],
                items: [],
            }
        default:
            return state
    }
}

export default cartReducer