module.exports = {
    title: 'Unlock2Ride',
    home: 'Shop',
    montage: 'Montage',
    about: 'About',
    contact: 'Contact',
    blog: 'Blog',
    all: 'All',
    posts: 'Posts',
    talks: 'Talks',
    'posts.seeMore': 'See all posts →',
    tags: 'Post Tags',
    'tags.allTagsLink': 'Browse all tags',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
        one {post}
        other {posts}
      } tagged with`,
    call: 'Schedule a call',
    'logo_alt': 'Go to unlock2ride.com',
    'company_info.registrationNumber': 'Registration:',
    'company_info.taxNumber': 'Tax:',
    'index.featured': 'Featured posts',
    'index.stories': 'All stories',
    'notification_info.only_android_devices': 'Currently the application is available only for Android devices !',
    'header_info.option_1': 'Lock attached to your bike',
    'header_info.option_2': 'Easily manage with your smartphone',
    'header_info.option_3': 'No need to search for key or memorize code', 
    'header_info.option_4': 'When a bicycle is moved, an alarm is triggered and a notification is sent with exact location',
    'header_info.option_5': 'Have you lost your bicycle ? You can use the navigation to your bicycle from your current location',
    'info_secure_your_bicycle.title': 'Secure your bike with smart bicycle lock Unlock2Ride',
    'info_load_alarm.title': 'Loud alarm and notification of attempted theft',
    'info_load_alarm.description': 'Lock or unlock the lock with your smartphone - all you need is a connection to a mobile or WIFI network. <br /> <br /> In the event of an attempted theft, a loud alarm is triggered in the lock, alerting passers-by to the attempted theft. <br /> <br /> The alarm is triggered at the slightest movement, so the first alarm is just a warning at you will also be notified of the next alarm, as your lock will immediately notify you of the exact GPS location of your lock. <br /> <br /> You will receive a link to navigate to the exact location in a message. It will guide you to your lock and your bike. <br /> <br /> You can receive messages trough different notiification channels - depending on the active subscription: <br /><br />',
    'info_load_alarm.option_1': 'Text message to your phone number',
    'info_load_alarm.option_2': 'Email message',
    'info_load_alarm.option_3': 'Push message in the Unlock2Ride mobile application',
    'info_battery_level_notification.title': 'Low battery alarm of bicycle lock',
    'info_battery_level_notification.description': 'Users can set battery level under which notifications will be triggered.<br /><br />After smart bike lock battery drops under that level notification can be sent to user through 3 notification channels, depending on your active subscription:<br /><br />',
    'info_battery_level_notification.option_1': 'Text message to your phone number',
    'info_battery_level_notification.option_2': 'Email message',
    'info_battery_level_notification.option_3': 'Push message in the Unlock2Ride mobile application',
    'info_mandatory_equipment.title': 'Mandatory equipment for every cyclist',
    'info_mandatory_equipment.robbery.title': 'Security',
    'info_mandatory_equipment.gpsTracking.title': 'GPS tracking',
    'info_mandatory_equipment.bell.title': 'Loud alarm',
    'info_mandatory_equipment.email.title': 'Notifications',
    'info_mandatory_equipment.forgot.title': 'Find bicycle',
    'info_mandatory_equipment.lowBattery.title': 'Level of battery',
    'info_mandatory_equipment.robbery': 'Ensure maximum safety for your bike with the Unlock2Ride app, which offers you:',
    'info_mandatory_equipment.gpsTracking': 'GPS tracking allows you to view the current position of your bike at any time',
    'info_mandatory_equipment.bell': 'Loud alarm - when moving the bike in case of attempted theft or damage to the bike',
    'info_mandatory_equipment.email': 'If the Alarm is triggered - you will receive an immediate message and notification on your phone and mail, depending on your active subscription:',
    'info_mandatory_equipment.forgot': 'Forgot your bike? The app allows you to navigate to your bike or desired destination',
    'info_mandatory_equipment.lowBattery': 'Application notifies you of the status of your battery when it falls below a certain configured level',
    'facts.rotating_slider_1.title': '“In Europe, about 3 million bicycles are stolen a year“',
    'facts.rotating_slider_2.title': '“Less than 10% of bikes are found“',
    'facts.rotating_slider_3.title': '“They steal all kinds of bikes, regardless of value and brand“',
    'facts.rotating_slider_4.title': '“Thieves steal about the same number of new and old bikes a year“',
    'facts.rotating_slider_5.title': '“Most bicycle thefts are recorded in cities, but this does not mean that they are not stolen in other places“',
    'facts.rotating_slider_6.title': '“The more difficult it is for a robber, the more likely he or she will be noticed and chased away“',
    'mounting_page_title': 'Mounting of bike lock',
    'mounting_page_process': 'Mounting process',
    'mounting_page_brackets_info.description_1': 'Place the lock on the wheel with the lock holder. There are several ways of installation, as the models of bicycles also differ from each other. You can use the following to install: ',
    'mounting_page_brackets_info_item_1': 'clamps for attaching the lock to the wheel',
    'mounting_page_brackets_info_item_2': 'set for mounting the lock on the wheel brakes',
    'mounting_page_brackets_info_item_3': 'the universal lock bracket is compatible with various wheel models, due to its universal design',
    'mounting_page_brackets_info.description_2': 'Whichever option you choose, bike lock can be mounted without mechanical intervention in the wheel frame.',
    'mounting_page_brackets_info.description_3': 'Worried that the lock or bracket is not suitable for your bike? Send us information about your bike model and pictures to our email address info@unlock2ride.com.',
    'mounting_page_brackets_info.description_4': '* The lock can also be used without mounting on the bike, but to ensure greater safety of the bike, we recommend that the Unlock2Ride lock is mounted on the bike frame.',
    'mounting_page_brackets_info.description_5': 'When ordering the lock you can choose montage in the last step and arrange the installation of your bike lock with us.',
    'mounting_page_brackets_info.condition': 'The bicycle lock may only be installed if the following conditions are met:',
    'mounting_page_montage_condition_1': 'Unlock2Ride is compatible with all bicycle tires with a width less than 64mm.',
    'mounting_page_montage_condition_2': 'The bicycle frame should have a distance of at least 44mm.',
    'mounting_page_montage_condition_3': 'If installation with an existing boot is not possible, the lock can also be fitted to the front wheel',
    'mounting_page_montage_condition_4': 'Unlock2Ride is compatible with all bicycle brake types (hub brake, caliper brake, v-brake, cantilever brake, disk brake)',
    'mounting_page_montage_image_alt_text_process_1': 'Mounting process of bike lock step 1',
    'mounting_page_montage_image_alt_text_process_2': 'Mounting process of bike lock step 2',
    'mounting_page_montage_image_alt_text_process_3': 'Mounting process of bike lock step 3',
    'mounting_page_montage_image_alt_text_supported_bicycle_models': 'Supported bicycle models',
    'mounting_page_montage_image_alt_text_supported_bicycle_brakes': 'Supported bicycle brakes',
    'cooperation_page.title': 'Cooperation',
    'cooperation_page.description': 'We invite stores and services with cycling equipment for the promotion and installation of smart locks in Slovenia. <br /> <br /> We also invite partners for bicycle rental. The Unlock2Ride system is ready for the bicycle exchange system. <br /> <br /> <strong> If you have a business idea and need our services, contact us at info@unlock2ride.com and we will be happy to respond . </strong>',
    "catalog_page_price_from": "From {price} /month",
    "catalog_page_subscription_included": "Subsription included for {months} months",
    'catalog_page_info.text_1': 'Keyless opening with smart phone',
    'catalog_page_info.text_2': 'Alarm on movement + notification on email with location and optional SMS',
    'catalog_page_info.text_3': 'GPS tracking',
    'catalog_page_info.text_4': 'Find my bike',
    'catalog_page_info.text_5': 'Alarm on low battery + notification on email and optional SMS',
    'catalog_page_info.text_6': 'Navigate me to my bicycle',
    'catalog_page_info.text_7': 'Optional: Additional SMS notifications (Choose on last step of order)',
    "product_page_tabs_power": "Power",
    "product_page_tabs_gnss": "GNSS",
    "product_page_tabs_cellular": "Cellular",
    "product_page_tabs_interface": "Interface",
    "product_page_tabs_physical": "Physical",
    "product_page_tabs_feature": "Feature",
    "copyright": "All rights reserved.",
    "image_alt_homepage": "Smart bike lock",
    "image_alt_sim_card": "Sim cart",
    "logout": "Logout",
    "sign_out_logout_successfully": "Logged out successfully.",
    "checkout_discount_code": "Discount code",
    "checkout_discount_code_activated": "Activated code",
    "checkout_discount_code_action": "Apply",
    "checkout_discount_code_action_remove": "Remove",
    "checkout_discount_code_apply": "Apply discount code",
    "checkout_discount_code_success": "Discount code was applied successfully",
    "checkout_discount_code_error": "Discount code not found",
    "checkout_discount_code_removed": "Discount code removed",
    "checkout_add_to_cart": "Add to cart",
    "checkout_add_to_cart_success": "Item added to cart",
    "checkout_add_to_cart_error": "Product is currently not available",
    "checkout_remove_from_cart_success": "Item removed from cart",
    "checkout_remove_from_cart_error": "Error: Removing item from cart",
    "checkout_out_of_stock": "Out of stock",
    "checkout_cart_product": "Product",
    "checkout_cart_description": "Description",
    "checkout_cart_quantity": "Quantity",
    "checkout_cart_price": "Value",
    "checkout_cart_row_total_price": "Amount",
    "checkout_cart_remove": "Remove",
    "checkout_cart_stripe_payment": "Payment",
    "checkout_cart_stripe_name": "Name",
    "checkout_cart_stripe_card": "Card",
    "checkout_cart_stripe_pay": "Pay now",
    "checkout_cart_stripe_pay_processing": "Processing...",
    "checkout_cart_sidebar_products": "Products",
    "checkout_cart_sidebar_subscriptions": "Subscriptions",
    "checkout_cart_sidebar_total_product_price": "Products total",
    "checkout_cart_sidebar_total_service_price": "Services total",
    "checkout_cart_sidebar_summary": "Summary",
    "checkout_cart_sidebar_shipping": "Shipping",
    "checkout_cart_sidebar_total_without_discount": "Total without discount",
    "checkout_cart_sidebar_total_discount": "Discount",
    "checkout_cart_sidebar_total_with_discount": "Total",
    "checkout_cart_sidebar_deposit": "Deposit",
    "checkout_cart_subscription": "Subscription",
    "checkout_cart_subscription_package": "Package",
    "checkout_cart_subscription_package_choose": "Choose package",
    "checkout_cart_subscription_for": "Subscription for",
    "checkout_cart_subscription_total": "Subscriptions total",
    "checkout_error_no_subscription_selected": "Please choose subscription",
    "checkout_proceed": "Proceed",
    "checkout_step_authentification": "Authentification",
    "checkout_step_shipping_billing": "Shipping & Billing",
    "checkout_step_shipping_billing_shipping_address": "Shipping address",
    "checkout_step_shipping_billing_billing_address": "Billing address",
    "checkout_step_shipping_billing_address": "Address",
    "checkout_step_shipping_billing_postal_code": "ZIP / Postal code",
    "checkout_step_shipping_billing_city": "City",
    "checkout_step_shipping_billing_country": "Select country",
    "checkout_step_shipping_billing_billing_address_equals_shipping_address": "Billing Address is the same as the Shipping Address",
    "checkout_step_payment": "Payment",
    "checkout_cart_is_expired": "Your shopping cart expired",
    "checkout_cart_is_empty": "Your shopping cart is empty",
    "settings_packages_info_price_explanation": "If yearly subscription is choosen user receives identical quota per month for yearly reduced price.",
    "settings_packages_info_trial_information": "After order is completed bike lock will be active immediatelly.",
    "settings_packages_info_trial_first_charge_after": "Subscription starts billing after {afterMonths} months",
    "settings_packages_select": "Select",
    "supported_country_SI": "Slovenia",
    "cart_empty": "Your cart is empty",
    "cart_go_to_checkout": "To checkout",
    "error_404": "Error 404",
    "page_not_found": "Page not found!",
    'pages_meta_title_contact': 'Contact',
    'pages_meta_title_cooperation': 'Cooperation',
    "pages_meta_title_login": "Login",
    "pages_meta_title_forgot_password": "Forgot password",
    "pages_meta_title_terms_and_conditions": "Terms and conditions",
    "pages_meta_title_register": "Register",
    "cookie_title": "This website uses cookies to enhance the user experience.",
    "cookie_accept": "Accept",
    "cookie_decline": "Decline",
    "contact_form_submit": "Submit",
    "contact_form_submit_loading": "Sending...",
    "contact_form_name": "Your name",
    "contact_form_email": "Your email",
    "contact_form_subject": "Subject",
    "contact_form_message": "Message",
    "contact_form_success": "Message sent successfully",
    "contact_form_error": "Error occured while sending message. Please call us.",
    "page_login_login": "Login",
    "page_login_login_start": "Login",
    "page_login_not_a_member": "Not a member?",
    "page_register_register": "Register",
    "page_register_register_start": "Register",
    "page_register_already_a_member": "Already a member?",
    "page_forgot_password": "Forgot password",
    "page_forgot_password_start": "Reset password",
    "page_forgot_password_did_you_forgot_password": "Did you forgot password?",
    "general_enter_confirmation_code_title": "Account verification.",
    "general_enter_confirmation_code_description": "To verify your account please enter confirmation code that you have received by email (check also spam folder).",
    "general_confirm_password": "Confirm Password",
    "forgot_password_enter_confirmation_code_description": "To change your password enter confirmation code that you have received by email (check also spam folder).",
    "formik_validation_shippingFirstname_is_too_short": "Provided firstname is too short.",
    "formik_validation_shippingFirstname_is_too_long": "Provided firstname is too long.",
    "formik_validation_shippingFirstname_is_required": "Please provide firstname",
    "formik_validation_shippingLastname_is_too_short": "Provided lastname is too short.",
    "formik_validation_shippingLastname_is_too_long": "Provided lastname is too long.",
    "formik_validation_shippingLastname_is_required": "Please provide lastname",
    "formik_validation_shippingAddress_is_too_short": "Provided address is too short.",
    "formik_validation_shippingAddress_is_too_long": "Provided address is too long.",
    "formik_validation_shippingAddress_is_required": "Please provide address",
    "formik_validation_shippingPostalCode_is_too_short": "Provided postal code is too short.",
    "formik_validation_shippingPostalCode_is_too_long": "Provided postal code is too long.",
    "formik_validation_shippingPostalCode_is_required": "Please provide postal code",
    "formik_validation_shippingCity_is_too_short": "Provided city is too short.",
    "formik_validation_shippingCity_is_too_long": "Provided city is too long.",
    "formik_validation_shippingCity_is_required": "Please provide city",
    "formik_validation_shippingCountryValue_is_too_short": "Provided country is too short.",
    "formik_validation_shippingCountryValue_is_too_long": "Provided country is too long.",
    "formik_validation_shippingCountryValue_is_required": "Please provide country",
    "formik_validation_billingFirstname_is_too_short": "Provided firstname is too short.",
    "formik_validation_billingFirstname_is_too_long": "Provided firstname is too long.",
    "formik_validation_billingFirstname_is_required": "Please provide firstname",
    "formik_validation_billingLastname_is_too_short": "Provided lastname is too short.",
    "formik_validation_billingLastname_is_too_long": "Provided lastname is too long.",
    "formik_validation_billingLastname_is_required": "Please provide lastname",
    "formik_validation_billingAddress_is_too_short": "Provided address is too short.",
    "formik_validation_billingAddress_is_too_long": "Provided address is too long.",
    "formik_validation_billingAddress_is_required": "Please provide address",
    "formik_validation_billingPostalCode_is_too_short": "Provided postal code is too short.",
    "formik_validation_billingPostalCode_is_too_long": "Provided postal code is too long.",
    "formik_validation_billingPostalCode_is_required": "Please provide postal code",
    "formik_validation_billingCity_is_too_short": "Provided city is too short.",
    "formik_validation_billingCity_is_too_long": "Provided city is too long.",
    "formik_validation_billingCity_is_required": "Please provide city",
    "formik_validation_billingCountryValue_is_too_short": "Provided country is too short.",
    "formik_validation_billingCountryValue_is_too_long": "Provided country is too long.",
    "formik_validation_billingCountryValue_is_required": "Please provide country",
    /*
   "formik_validation_address_is_too_short": "Provided address is too short.",
    "formik_validation_address_is_too_long": "Provided address is too long.",
    "formik_validation_address_is_required": "Please provide address",
    "formik_validation_postalCode_is_too_short": "Provided postal code is too short.",
    "formik_validation_postalCode_is_too_long": "Provided postal code is too long.",
    "formik_validation_postalCode_is_required": "Please provide postal code",
    "formik_validation_city_is_too_short": "Provided city is too short.",
    "formik_validation_city_is_too_long": "Provided city is too long.",
    "formik_validation_city_is_required": "Please provide city",
    "formik_validation_country_is_too_short": "Provided country is too short.",
    "formik_validation_country_is_too_long": "Provided country is too long.",
    "formik_validation_country_is_required": "Please provide country",
*/
    // IDENTICAL TO MOBILE TRANSLATIONS
    "general_firstname": "Firstname",
    "general_lastname": "Lastname",
    "general_email": "Email",
    "general_telephone": "Telephone",
    "general_password": "Password",
    "general_dialog_box_apply": "Apply",
    "general_dialog_box_close": "Close",
    "general_dialog_box_accept": "Accept",
    "general_dialog_box_decline": "Decline",
    "general_enter_confirmation_code": "Enter confirmation code, which you receive on email.",
    "general_enter_confirmation_code_text": "Confirmation code",
    "general_enter_confirmation_code_not_applied": "Confirmation code not applied",
    "general_error_confirmation_code_incorrect": "Incorrect confirmation code. Please try again.",
    "general_error_internal_application_error": "Internal application error",
    "general_error_server_not_available": "Server not available",
    "general_error_content_not_available": "Content not available",
    "general_error_email_or_password_wrong": "Email or password incorrect",
    "settings_change_password_old_password": "Old password",
    "settings_change_password_new_password": "New password",
    "settings_change_password_confirm_password": "Confirm password",
    "settings_packages_basic": "Basic",
    "settings_packages_standard": "Standard",
    "settings_packages_advanced": "Advanced",
    "settings_packages_premium": "Premium",
    "settings_packages_price_per_month": "month",
    "settings_packages_price_per_year": "year",
    "settings_packages_info_alarm_battery_notification": "Low battery notification",
    "settings_packages_info_alarm_movement_notification": "Bike lock movement notification",
    "settings_packages_info_bike_lock": "Bicycle lock",
    "settings_packages_info_bike_lock_gps_positioning": "Active Gps tracking",
    "settings_packages_info_bike_lock_sim_card_subscription": "Active sim card",
    "settings_packages_info_maps": "Maps",
    "settings_packages_info_maps_autocomplete": "Search on maps",
    "settings_packages_info_maps_directions": "Route directions",
    "settings_packages_info_maps_navigate": "Route navigation",
    "settings_packages_info_notification_type_email": "Email",
    "settings_packages_info_notification_type_sms": "Sms",
    "settings_packages_info_notification_type_push_notification": "Push notification",
    "settings_packages_info_unlimited_usage": "Unlimited",
    "settings_packages_info_included": "Included",
    "settings_packages_info_limited_usage": "Month",
    "settings_packages_info_price_for_each_additional_bike_lock": "Users with active subscription can add additional bike locks for %{PricePerMonth} per month or %{pricePerYear} per year for this package.",
    "forgot_password": "Forgot password",
    "forgot_password_submit": "Reset password",
    "forgot_password_error_user_action_denied": "User either not exists or is not verified",
    "forgot_password_success": "Password was successfully changed.",
    "sign_in_login_successfully": "Logged in successfully.",
    "sign_in_wrong_username_or_password": "Wrong username or password.",
    "formik_validation_name_is_too_short": "Provided name is too short.",
    "formik_validation_name_is_too_long": "Provided name is too long.",
    "formik_validation_name_is_required": "Please provide name.",
    "formik_validation_subject_is_too_short": "Provided subject is too short.",
    "formik_validation_subject_is_too_long": "Provided subject is too long.",
    "formik_validation_subject_is_required": "Please provide subject.",
    "formik_validation_message_is_too_short": "Provided message is too short.",
    "formik_validation_message_is_too_long": "Provided message is too long.",
    "formik_validation_message_is_required": "Please provide message.",
    "formik_validation_firstname_is_too_short": "Provided firstname is too short.",
    "formik_validation_firstname_is_too_long": "Provided firstname is too long.",
    "formik_validation_firstname_is_required": "Please provide firstname",
    "formik_validation_lastname_is_too_short": "Provided lastname is too short.",
    "formik_validation_lastname_is_too_long": "Provided lastname is too long.",
    "formik_validation_lastname_is_required": "Please provide lastname",
    "formik_validation_password_is_too_short": "Password must be longer then 3 chars.",
    "formik_validation_password_is_too_long": "Password should not exceed 20 chars.",
    "formik_validation_password_is_required": "Please provide password",
    "formik_validation_oldPassword_is_too_short": "Password must be longer then 3 chars.",
    "formik_validation_oldPassword_is_too_long": "Password should not exceed 20 chars.",
    "formik_validation_oldPassword_is_required": "Please provide password",
    "formik_validation_newPassword_is_too_short": "Password must be longer then 3 chars.",
    "formik_validation_newPassword_is_too_long": "Password should not exceed 20 chars.",
    "formik_validation_newPassword_is_required": "Please provide password",
    "formik_validation_confirmPassword_is_too_short": "Password must be longer then 3 chars.",
    "formik_validation_confirmPassword_is_too_long": "Password should not exceed 20 chars.",
    "formik_validation_confirmPassword_is_required": "Please confirm password",
    "formik_validation_confirmPassword_is_not_one_of": "Passwords must match",
    "formik_validation_email_is_required": "Email address is required",
    "formik_validation_email_is_invalid": "Please provide valid email",
    "formik_validation_termsAccepted_is_not_one_of": "Accept Terms & Conditions is required",
    "formik_validation_privacyPolicyAccepted_is_not_one_of": "Accept Privacy Policy is required",
    "formik_validation_phone_number": "Phone number is invalid",
    "sign_up_confirm_password": "Confirm Password",
    "sign_up_agree_with_terms_and_conditions": "I read and agree to Terms & Conditions",
    "sign_up_already_have_account_sign_in": "Already have account? Sign In",
    "sign_up_registration_completed": "You have successfully register",
    "sign_up_registration_error": "Error while registering user",
    "policy_privacy_title": "Protection of personal data",
    "policy_privacy_agree": "I agree that KreLab, programming and consulting, Tilen Kreča s.p., as the operator of the website, may process the data described above in order to determine my interest in the products, services and other solutions of the company and contact me about them. via e-mail or telephone. I confirm that I am aware of the protection of personal data. ",
    "policy_privacy_additional_info_title": "Privacy Guarantee",
    "policy_privacy_additional_info_description": "Information from the Contact Form will not be provided to a third party under any circumstances or used for any other purpose except for the purpose of the content of the message sent on the contact form or the conclusion of a business relationship.",
};